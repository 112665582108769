import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import FormInput from '@components/form/form-input'
import { getStaleMins } from '@helpers/stale-timer'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  Avatar,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { UserService } from '@services/api-admin/settings-user'
import DocumentService from '@services/api-manage/docs'

import NoteBox from '../notebox'

interface NotesDialogProps {
  open: boolean
  handler: () => void
  docData?: any
}

const NotesDialog = ({ open, handler, docData }: NotesDialogProps) => {
  const { user } = useAuth0()

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = useForm({
    shouldUnregister: false,
  })

  const { data } = useQuery(
    ['author'],
    () => UserService.getUserByEmail(user?.email),
    { ...getStaleMins() }
  )

  const { data: notesData, refetch: notesRefetch } = useQuery(
    ['notes', docData?.id],
    () => DocumentService.getNotesOnDoc(docData?.id),
    { ...getStaleMins(), enabled: !!docData?.id }
  )
  const notesRes = notesData?.data

  const onSubmit = (dt: any) => {
    if (data) {
      const payload = {
        note: dt.notes,
        userId: data[0].user_id,
        docId: docData.id,
      }
      postNote(payload)
    }
  }

  const { mutate: postNote, isLoading: isPosting } = useMutation(
    (payload: any) => {
      return DocumentService.postNoteOnDoc(payload)
    },
    {
      onSuccess: () => {
        notesRefetch()
        reset({ notes: '' })
      },
      onError: () => {
        toast.error('Could not post note', { autoClose: 5000 })
      },
    }
  )

  return (
    <Dialog open={open} size={'xl'} handler={() => undefined} id="notes-dialog">
      <DialogHeader className="flex justify-between">
        Notes
        <XMarkIcon
          className="w-7 cursor-pointer"
          onClick={() => {
            handler()
          }}
        />
      </DialogHeader>
      <DialogBody divider className="flex flex-col overflow-auto p-5">
        <div className="flex flex-row overflow-auto h-96">
          {!!notesRes && !!notesRes.length && data ? (
            <div className="w-full">
              {notesRes.map((note: any, idx: number) => (
                <NoteBox
                  key={idx}
                  note={note}
                  notesRefetch={notesRefetch}
                  currentUser={data[0]}
                  docId={docData.id}
                />
              ))}
            </div>
          ) : (
            <div className="flex justify-center w-full h-full items-center">
              No Notes Yet
            </div>
          )}
        </div>
      </DialogBody>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogFooter className="flex justify-center flex-col">
          <div className="flex flex-row w-full items-center">
            <Avatar
              src={user?.picture}
              alt="avatar"
              variant="circular"
              size="sm"
              className="w-12 h-12 mx-2 mt-3"
            />
            <div className="mx-2 mt-5 w-full">
              <FormInput
                type="textarea"
                value={getValues('notes')}
                {...register('notes', {
                  required: `Your input is required`,
                  disabled: isSubmitting,
                  onChange: (e: any) => {
                    setValue('notes', e.target.value, {
                      shouldValidate: true,
                    })
                  },
                })}
                error={errors?.notes?.message as string}
              />
            </div>

            <Button
              type="submit"
              color="primary"
              className="mx-2 mt-3 h-14"
              disabled={!isValid || isPosting}
            >
              Post
            </Button>
          </div>
        </DialogFooter>
      </form>
    </Dialog>
  )
}

export default NotesDialog

import React, { useContext, useEffect, useRef, useState } from 'react'
import { formatISO } from 'date-fns'
import { useInfiniteQuery, useMutation } from 'react-query'
import * as XLSX from 'xlsx'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import useDeepCompareFilter from '@components/filters/deep-filter-compare-effect'
import LoadingBar from '@components/loading/loading-bar'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { BorrowingBaseService } from '@services/api-manage/monitor-borrowing-base'

import { renderDecimal, SEPARATOR_COLUMN } from './helper'

const SKIPPED_COLUMNS = [SEPARATOR_COLUMN]
const LIMIT = 1000

const ExportDialog = () => {
  const [is_downloading, setIsDownloading] = useState<boolean>(false)
  const [initiateExport, setInitiateExport] = useState<boolean>(false)

  const [canExportBB, setCanExportBB] = useState<boolean>(false)
  const [canExportCollateral, setCanExportCollateral] = useState<boolean>(false)

  const [BBData, setBBData] = useState<any>(undefined)
  const [historicalData, setHistoricalData] = useState<any>(undefined)
  const [Cdownloaded, setCdownloaded] = useState<boolean>(false)
  const [collateralLoading, setCollateralLoading] = useState<number[]>([0, 0])

  const [bbError, setBBError] = useState<boolean>(false)
  const [historyError, setHistoryError] = useState<boolean>(false)

  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeFacilityId, calculationDate } = appliedFilters
  const { facilities = [] } = optionFilters

  const activeFacility = facilities?.find(
    (f: FacilityFilter) => f.facility_id === activeFacilityId
  )

  const FEATURE = `${activeFacilityId}_manage_monitor_borrowing-base`

  useDeepCompareFilter(() => {
    setCdownloaded(false)
    setIsDownloading(false)
  }, [appliedFilters])

  // Access Permission Toggle with useRed to prevent rerenders
  const { get_access } = useUserAccessFeature()
  const previousFeatureRef = useRef<string | null>(null)
  useEffect(() => {
    if (previousFeatureRef.current !== FEATURE) {
      setCanExportBB(get_access(`${FEATURE}_borrowing-base`, 'export'))
      setCanExportCollateral(get_access(`${FEATURE}_collateral`, 'export'))
      previousFeatureRef.current = FEATURE
    }
  }, [activeFacilityId])

  const filters = {
    slug_name: company?.slug_name ?? '',
    facility_id: activeFacilityId,
    calculation_date: formatISO(calculationDate),
  }

  const { mutate: mutateBB, isLoading: loadingBB } = useMutation({
    mutationKey: ['Borrowing Base', filters],
    mutationFn: () => BorrowingBaseService.get(filters),
    onSuccess: (data: any) => {
      setBBData(data)
      setBBError(false)
    },
    onError: () => {
      setBBError(true)
    },
  })

  const { mutate: mutateHistory, isLoading: loadingHistory } = useMutation({
    mutationKey: ['Historical Surplus', filters],
    mutationFn: () => BorrowingBaseService.historicalSurplus(filters),
    onSuccess: (data: any) => {
      setHistoricalData(data)
      setHistoryError(false)
    },
    onError: () => {
      setHistoryError(true)
    },
  })

  const {
    data: collateralData,
    fetchNextPage,
    hasNextPage,
    isError: collateralQueryError,
  } = useInfiniteQuery({
    queryKey: ['Collaterals', filters],
    queryFn: ({ pageParam = 0 }) => {
      return BorrowingBaseService.collateral({
        ...filters,
        page: pageParam,
        per_page: LIMIT,
      })
    },
    getNextPageParam: (lastPage, pages) => {
      const totalFetched = pages.reduce(
        (sum, page) => sum + page.data.length,
        0
      )
      const totalItems = lastPage.total

      // avoid rerendering the same thing
      const [currentFetched, currentTotal] = collateralLoading
      if (currentFetched !== totalFetched || currentTotal !== totalItems) {
        setCollateralLoading([totalFetched, totalItems])
      }
      if (totalFetched < totalItems) {
        return pages.length // This increments page number
      }
      return undefined
    },
    enabled: initiateExport && canExportCollateral,
  })

  // We need a natural rerendering mechanism to start the mutation or infinite query or else we break Hook rules and hasNextPage is asynchronously undefined. We start this chain with a natural cycle with 'initiateExport' If canExportCollateral is true and initiateExport is true, start fetching pages.
  useEffect(() => {
    if (initiateExport) {
      if (canExportBB) {
        mutateBB()
        mutateHistory()
      }
    }
    if (canExportCollateral && initiateExport && !Cdownloaded) {
      fetchAllCollateralPages()
    }
  }, [initiateExport])

  // Second UE for heavy lifting of infinite query.
  useEffect(() => {
    if (!hasNextPage && collateralData) {
      setCdownloaded(true)
    } else if (initiateExport && hasNextPage) {
      fetchAllCollateralPages()
    }
  }, [hasNextPage, collateralData])

  const fetchAllCollateralPages = async () => {
    if (hasNextPage) {
      await fetchNextPage()
    }
  }

  const _download = async () => {
    setIsDownloading(true)
    const workbook = XLSX.utils.book_new()

    const parts = [
      { component: 'Borrowing Base', data: BBData },
      { component: 'Historical Surplus', data: historicalData },
      { component: 'Collaterals', data: collateralData, paginated: true },
    ].filter(part => part.data !== undefined)

    parts.forEach((p: any) => {
      const data = p.paginated
        ? (p as any).data?.pages?.reduce((prev: any, cur: any) => {
            return [...prev, ...cur.data]
          }, [])
        : p.data

      const all_contents = data.reduce(
        (pd: { [key: string]: any[] }, d: any) => {
          const { detail_data, ...res_d } = d

          // Use p.component directly now
          return {
            ...pd,
            [p.component]: [
              ...(pd[p.component] ?? []),
              Object.keys(res_d).reduce((prd, rd) => {
                return SKIPPED_COLUMNS.includes(rd)
                  ? prd
                  : { ...prd, [rd]: res_d[rd] }
              }, {}),
            ],
            ...(detail_data?.length > 0
              ? { [res_d.display_name]: detail_data }
              : {}),
          }
        },
        {}
      )

      Object.keys(all_contents).forEach(c => {
        const worksheet = XLSX.utils.json_to_sheet(all_contents[c] ?? [])
        // max xlsx length is 31
        XLSX.utils.book_append_sheet(workbook, worksheet, c.slice(0, 31))
      })
    })

    await XLSX.writeFile(
      workbook,
      `${company?.legal_name} - ${activeFacility?.facility_name} Borrowing Base ${calculationDate}.xlsx`
    )

    setIsDownloading(false)
  }

  const basicLoading: React.ReactNode = (
    <div
      className="bg-teal"
      style={{
        height: '100%',
        width: '100%',
        animation: 'pulse 2s infinite',
      }}
    />
  )

  const chunkLoading: React.ReactNode = (
    <div
      className="bg-primary-hover/75"
      style={{
        height: '100%',
        width: `${(collateralLoading[0] / collateralLoading[1]) * 100}%`,
        background:
          'linear-gradient(90deg, rgba(0, 150, 255, 0) 0%, rgba(0, 150, 255, 0.5) 50%, rgba(0, 150, 255, 0) 100%)',
        backgroundSize: '200% 100%',
        animation: 'pulse 2s infinite', // Apply the animation
      }}
    />
  )

  return (
    <>
      <Button
        className="mr-0.25!shadow-none border border-primary-main text-primary-main py-2 px-6 hover:bg-primary-main hover:text-neutral-white"
        onClick={() => {
          setInitiateExport(true)
        }}
      >
        <Typography className="capitalize font-medium text-sm">
          Export
        </Typography>
      </Button>
      <Dialog open={initiateExport} handler={() => undefined}>
        <DialogHeader className="justify-between">
          <Typography className="capitalize font-medium text-xl">
            Export Borrowing base
          </Typography>
          <XMarkIcon
            onClick={() => {
              setInitiateExport(false)
            }}
            className="w-6 h-6 cursor-pointer hover:opacity-50"
          />
        </DialogHeader>
        <DialogBody divider className="flex flex-col p-6">
          <div className="text-sm text-center text-cc-text-primary">
            Please try to <span className="font-bold mx-0.5"> NOT CLOSE</span>{' '}
            the Borrowing Base downloader until export completes. Closing
            mid-download has a small chance to upset data.
          </div>
          <div className="mt-8 flex flex-col gap-4 max-h-[50vh] overflow-y-auto">
            <LoadingBar
              field="Borrowing Base"
              validation={canExportBB}
              loadingCondition={loadingBB}
              loadingLogic={basicLoading}
              errorCondition={bbError}
            />
            <LoadingBar
              field="Historical Surplus"
              validation={canExportBB}
              loadingCondition={loadingHistory}
              loadingLogic={basicLoading}
              errorCondition={historyError}
            />
            <LoadingBar
              field="Collaterals"
              validation={canExportCollateral}
              loadingCondition={!Cdownloaded}
              loadingLogic={chunkLoading}
              errorCondition={collateralQueryError}
              renderDecimal={
                <span className="text-sm flex-1 ml-2 normal-case">
                  {`(${renderDecimal(
                    collateralLoading[0],
                    0
                  )} of ${renderDecimal(collateralLoading[1], 0)})`}
                </span>
              }
            />
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={_download}
            disabled={
              (canExportBB && loadingBB) ||
              (canExportBB && loadingHistory) ||
              (canExportCollateral && !Cdownloaded) ||
              is_downloading
            }
            className="!shadow-none border py-2 px-6 border-primary-main text-primary-main active:border-1 hover:bg-primary-hover hover:text-neutral-white 
            hover:border-primary-hover
            disabled:text-cc-locked/50
            disabled:bg-neutral-white"
          >
            {is_downloading && (
              <ArrowPathIcon className="w-4 h-4 mr-4 text-primary-main animate-spin" />
            )}
            Download
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default ExportDialog

import { StyleSheet } from '@react-pdf/renderer'

export const tableStyles = StyleSheet.create({
  table: {
    width: 'auto',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },

  tableBody: {
    flexDirection: 'column',
  },

  tableCell: {
    margin: 0.5,
    marginTop: 5,
    fontSize: 7.5,
    alignContent: 'flex-start',
  },

  tableCellBold: {
    margin: 2,
    marginTop: 5,
    fontSize: 7.5,
    alignContent: 'flex-start',
    fontFamily: 'Times-Bold',
  },

  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    flexBasis: 0,
  },

  tableFootnote: {
    width: '100%',
    fontFamily: 'Times-Roman',
    fontSize: 8,
    margin: 5,
  },

  tableHeaderCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 1,
  },

  tableRow: {
    flexDirection: 'row',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },

  tableRowSeparated: {
    flexDirection: 'row',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 1,
  },

  tableView: {
    paddingTop: 5,
    paddingBottom: 25,
  },

  textTableTitle: {
    marginVertical: 5,
    fontSize: 12,
    fontFamily: 'Times-Bold',
    textDecoration: 'underline',
  },

  textTableTitleSmall: {
    marginVertical: 5,
    fontSize: 10,
    fontFamily: 'Times-Bold',
  },

  textLeft: {
    marginVertical: 5,
    fontSize: 10,
    fontFamily: 'Times-Roman',
    textAlign: 'left',
  },

  textLeftBold: {
    marginVertical: 5,
    fontSize: 10,
    fontFamily: 'Times-Bold',
    textAlign: 'left',
  },

  textRight: {
    marginVertical: 5,
    fontSize: 10,
    fontFamily: 'Times-Roman',
    textAlign: 'right',
  },

  textRightBold: {
    marginVertical: 5,
    fontSize: 10,
    fontFamily: 'Times-Bold',
    textAlign: 'right',
  },

  textCenter: {
    marginVertical: 5,
    fontSize: 10,
    fontFamily: 'Times-Roman',
    textAlign: 'center',
  },

  textCenterBold: {
    marginVertical: 5,
    fontSize: 10,
    fontFamily: 'Times-Bold',
    textAlign: 'center',
  },
})

import { noop } from 'lodash'

import { CategoriesResult } from '@interfaces/manage-reporting'

import {
  useCreateNegativeAdvanceRequest,
  useGenerateClientReport,
  useWriteCollateralFileHook,
} from './general'

export const useAfterEffects = (category: CategoriesResult) => {
  switch (category?.subcategory) {
    case 'coign-compliance-cert':
      return useGenerateClientReport(category)
    case 'coign-monthly-report':
      return useGenerateClientReport(category, true)
    case 'flexpath-waterfall':
      return useGenerateClientReport(category)
    case 'orange-bank-compliance-monthly':
    case 'orange-bank-compliance-quarterly':
      return useWriteCollateralFileHook()
    case 'orange-bank-payment-demand':
      return useCreateNegativeAdvanceRequest()
    case 'raistone-waterfall':
      return useGenerateClientReport(category)
    case 'specialty-capital-waterfall':
      return useGenerateClientReport(category, true)

    default:
      return { afterEffect: noop }
  }
}

import React from 'react'
import { format } from 'date-fns'

import { capitalizeWords } from '@helpers/string-formatter'
import { Text, View } from '@react-pdf/renderer'

import { styles } from '../../template_style'

import { tableStyles } from './style'
export * from './style'

export interface AllTableData {
  title?: string
  key?: string
  tableWidth: string
  footnote?: string
  titleRow?: {
    content: string
    width: string
  }[]
  data: TableCell[][]
  style?: Record<string, Record<string, string | number>>
  wrap?: boolean
}

export interface TableCell {
  content: number | string
  position: 'left' | 'center' | 'right'
  width: string
  capitalize?: boolean
  bold?: boolean
  style?: {
    container?: Record<string, string | number>
    text?: Record<string, string | number>
  }
}

const styleBuilder = (text: any, tight: boolean) => {
  if (text?.content || text?.content === 0) {
    const position = capitalizeWords(text.position) as
      | 'Left'
      | 'Center'
      | 'Right'
    const result = tableStyles[`text${position}${text.bold ? 'Bold' : ''}`]
    if (tight) {
      result.marginVertical = 1
      result.fontSize = 7
    }
    return result
  } else {
    return styles.text
  }
}

const renderRowData = (data: AllTableData, tight = false) => {
  if (data.data?.length) {
    return data.data.map((row: any, cellIndex: number) => (
      <View
        key={cellIndex}
        style={{
          ...(row?.[0]?.separator
            ? tableStyles.tableRowSeparated
            : tableStyles.tableRow),
          ...data.style?.tableRow,
        }}
      >
        {row.map((cell: Record<string, any>, cellIndex: number) => (
          <View
            key={`${data.title ?? 'summary'}-${row}-${cellIndex}`}
            style={{
              ...tableStyles.tableCol,
              ...data.style?.tableCol,
              width: cell.width,
              ...(cell.style?.container ?? {}),
            }}
          >
            <View
              style={
                tight
                  ? {
                      ...tableStyles.tableCell,
                      ...data.style?.tableCell,
                      margin: 0,
                      marginTop: 0,
                    }
                  : { ...tableStyles.tableCell, ...data.style?.tableCell }
              }
            >
              <Text
                style={{
                  ...styleBuilder(cell, tight),
                  ...data.style?.text,
                  ...(cell.style?.text ?? {}),
                }}
              >
                {cell.content &&
                  (cell.capitalize
                    ? capitalizeWords(cell.content as string)
                    : isNaN(new Date(cell.content) as unknown as number) ||
                      typeof cell.content === 'number' ||
                      !cell.content.includes('-')
                    ? cell.content
                    : format(new Date(cell.content), 'yyyy-MM-dd'))}
              </Text>
            </View>
          </View>
        ))}
      </View>
    ))
  } else {
    return (
      <View style={{ ...tableStyles.tableRow, ...data.style?.tableRow }}>
        <View
          style={{
            ...tableStyles.tableCol,
            ...data.style?.tableCol,
            width: '100%',
          }}
        >
          <View style={{ ...tableStyles.tableCell, ...data.style?.tableCell }}>
            <Text
              style={{ ...tableStyles.textCenter, ...data.style?.textCenter }}
            >
              No data
            </Text>
          </View>
        </View>
      </View>
    )
  }
}

export const Table = ({
  data,
  index,
  pageBreak = false,
  size = 'normal',
  tight = false,
}: {
  data: AllTableData
  index?: number | string
  size?: 'normal' | 'small'
  tight?: boolean
  pageBreak?: boolean
}) => {
  return (
    data && (
      <View
        wrap={data.wrap ?? false}
        style={{ width: data.tableWidth, height: 'auto' }}
        break={pageBreak}
      >
        {data.title && (
          <Text
            style={
              size === 'small'
                ? tableStyles.textTableTitleSmall
                : tableStyles.textTableTitle
            }
          >
            {(index ? index + (typeof index === 'number' ? '. ' : ' ') : '') +
              capitalizeWords(data.title.replaceAll('_', ' '))}
          </Text>
        )}
        <View
          wrap={data.wrap ?? false}
          style={{ ...tableStyles.tableView, ...data.style?.tableView }}
        >
          <View style={{ ...tableStyles.table, ...data.style?.table }}>
            {data?.titleRow && (
              <View
                style={{
                  ...tableStyles.tableRow,
                  ...data.style?.tableRow,
                  ...data.style?.headerRow,
                }}
              >
                {data.titleRow.map((row: any, index: number) => (
                  <View
                    key={`${data.title}-column-header-${index}`}
                    style={{
                      ...tableStyles.tableHeaderCol,
                      ...data.style?.tableHeaderCol,
                      width: row.width,
                    }}
                  >
                    <View
                      style={
                        tight
                          ? {
                              ...tableStyles.tableCell,
                              ...data.style?.tableCell,
                              margin: 0,
                              marginTop: 0,
                            }
                          : {
                              ...tableStyles.tableCell,
                              ...data.style?.tableCell,
                            }
                      }
                    >
                      <Text
                        style={
                          tight
                            ? {
                                ...tableStyles.textCenter,
                                ...data.style?.textCenter,
                                ...data.style?.headerRowText,
                                margin: 1,
                                fontSize: 8,
                              }
                            : {
                                ...tableStyles.textCenter,
                                ...data.style?.textCenter,
                                ...data.style?.headerRowText,
                              }
                        }
                      >
                        {row.content}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            )}
            {renderRowData(data, tight)}
          </View>
        </View>
        {data.footnote && (
          <Text
            style={{
              ...tableStyles.tableFootnote,
              ...data.style?.tableFootnote,
            }}
          >
            {data.footnote}
          </Text>
        )}
      </View>
    )
  )
}

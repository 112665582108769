import { useContext, useEffect, useState } from 'react'
import { endOfMonth, format, sub } from 'date-fns'
import moment from 'moment'

import AuthContext from '@contexts/auth'

const getLatestEndOfMonth = () => {
  const currentDate = new Date()
  if (format(currentDate, 'dd') === format(endOfMonth(currentDate), 'dd')) {
    return currentDate
  } else {
    return endOfMonth(sub(currentDate, { months: 1 }))
  }
}

export const useCoignMonthEndReportData = () => {
  const { appliedFilters, company } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters
  const [data, setData] = useState<any>([true, {}])

  const fileName = `${moment().unix()}_coign_mft_month_end_report.xlsx`

  const date = getLatestEndOfMonth()

  useEffect(() => {
    setData([
      false,
      {
        fileUrl: `${window.location.origin}/manage/monitor/actions?pid=${company?.slug_name}&facility=${activeFacilityId}&tab=document-centre&fileName=${fileName}`,
        excelUploadCategory: 'MFT - Month End Report',
        excelUploadTitle: `MFT - ${format(date, 'MM.yyyy')} Month End Report`,
        effectiveDate: format(date, 'yyyy-MM-dd'),
      },
    ])
  }, [company, activeFacilityId])

  return data
}

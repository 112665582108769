import React from 'react'
import moment from 'moment'

import { CashBalanceCertTemplateProps } from '@interfaces/manage-reporting'
import { Document, Page, Text, View } from '@react-pdf/renderer'

import { Footer, Table } from '../../../components'
import { styles } from '../../../template_style'

export const FsvnCashBalanceCertTemplate = ({
  agent,
  borrower,
  guarantors,
  agreementDate,
  effectiveDate,
  cashTableData,
  formData,
}: CashBalanceCertTemplateProps) => (
  <Document>
    <Page style={styles.body}>
      <Text style={styles.title}>
        Form of Compliance Certificate - Cash Balance
      </Text>
      <Text style={styles.text}>{moment().format('DD/MM/YYYY')}</Text>
      <Text style={styles.text}>{agent ? agent : '[Agent]'}</Text>
      <Text style={styles.text}>Reference is made to:</Text>
      <Text style={styles.text}>
        Loan and Security Agreement, dated:{' '}
        {agreementDate
          ? moment.utc(agreementDate).format('DD/MM/YYYY')
          : '[Date of Agreement]'}{' '}
        (as amended, restated, supplemented or otherwise modified from time to
        time), the “Loan Agreement”
      </Text>
      <Text style={styles.text}>Among :</Text>
      <Text style={styles.text}>
        the Borrower: {borrower || '[Borrower name]'}
      </Text>
      <Text style={styles.text}>
        the Guarantor: {guarantors || '[Intentionally left blank]'}
      </Text>
      <Text style={styles.text}>
        the Lenders: the financial institutions or entities from time to time
        defined therein
      </Text>
      <Text style={styles.text}>the Agent: {agent || '[Agent]'}</Text>
      <Text style={styles.text}>
        Date Range of Compliance Certificate:{` `}
        {effectiveDate
          ? moment.utc(effectiveDate).format('DD/MM/YYYY')
          : '[Date of certificate]'}
      </Text>
      <Text style={styles.text}>
        Capitalized terms used but not defined herein have the meanings
        specified in the Loan Agreement.
      </Text>
      <Text style={{ ...styles.text, textIndent: 32 }}>
        The undersigned is an officer of the Borrower, knowledgeable of all of
        the Obligors’ financial matters, is authorized to provide this
        certification regarding, and on behalf of, the Borrower, and hereby
        certifies in such capacity, that:
      </Text>
      <Text style={styles.textIndented}>
        In accordance with the terms and conditions of the Loan Agreement, the
        undersigned has made, or has caused to be made under the undersigned’s
        supervision, a review of the Obligor’s compliance for the period covered
        by this Compliance Certificate with all covenants, conditions and terms
        under the Loan Agreement, and all representations and warranties
        contained therein, and (i) except as described in the disclosure
        attached hereto, such review did not disclose, and the undersigned has
        no knowledge of, the existence of any condition or event which
        constitutes an Event of Default that has occurred and is continuing as
        of the date of this Compliance Certificate and (ii) attached is
        disclosure that specifies, in reasonable detail, the nature of any such
        condition or event and any action taken or proposed to be taken with
        respect thereto.
      </Text>
      {(cashTableData ?? []).map((tableData, index) => (
        <Table
          key={`cash-table-${index}`}
          data={{ ...tableData, wrap: false }}
        />
      ))}
      <View
        wrap={false}
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          marginTop: 20,
        }}
      >
        <View
          style={{
            width: '33%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Text style={styles.text}>Signed:</Text>
        </View>
        <View
          style={{
            width: '33%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>Director</Text>
          <Text style={styles.signTags}>[sig|req|signer1|signature]</Text>
          <Text style={styles.text}>
            {formData?.signers?.[0]?.name ?? '[signer name]'}
          </Text>
          <Text style={styles.text}>of</Text>
          <Text style={styles.textBold}>KOMODO ASSET I VCC</Text>
        </View>
        <View
          style={{
            width: '33%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>Director</Text>
          <Text style={styles.signTags}>[sig|req|signer2|signature]</Text>
          <Text style={styles.text}>
            {formData?.signers?.[1]?.name ?? '[signer name]'}
          </Text>
          <Text style={styles.text}>of</Text>
          <Text style={styles.textBold}>KOMODO ASSET I VCC</Text>
        </View>
      </View>
      <Footer />
    </Page>
  </Document>
)

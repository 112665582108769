import { MonitorOverviewResponse } from '@interfaces/manage-monitor-borrowing-base'

export const renderDecimal = (value?: any, decimal_number = 2) =>
  Intl.NumberFormat(undefined, {
    style: 'decimal',
    maximumFractionDigits: decimal_number,
    minimumFractionDigits: decimal_number,
  }).format(value)

export const SEPARATOR_COLUMN = 'separate_index'

export const is_number = (value: string) =>
  value?.match(/^\$?(([1-9]\d{0,2}(,\d{3})*)|0)?\.\d{1,2}$/)

export const is_percentage = (value: string) => value?.includes('%')

// extracts value by order number
export const extractDisplayValueByOrderNumber = (
  order: number,
  data?: MonitorOverviewResponse[]
): string => {
  const foundObject = data?.find(
    (d: MonitorOverviewResponse) => d.order === order
  )
  return foundObject?.displayValue || '0'
}

export const extractTitleByOrderNumber = (
  order: number,
  data?: MonitorOverviewResponse[]
): string => {
  const foundObject = data?.find(
    (d: MonitorOverviewResponse) => d.order === order
  )
  return foundObject?.title || ''
}

import { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import AuthContext from '@contexts/auth'
import { formatDownloadURL } from '@helpers/downloadURL-formatter'
import { getStaleMins } from '@helpers/stale-timer'
import { Facility } from '@interfaces/facility'
import { CovenantType } from '@interfaces/manage-monitor-covenant'
import { CovenantService } from '@services/api-manage/monitor-covenant'

import { getDates } from '../helper'

export const useOrangeBankComplianceCertData = (
  cadence: 'monthly' | 'quarterly',
  getCovenants = false
) => {
  const { appliedFilters, company, optionFilters } = useContext(AuthContext)
  const { facilities } = optionFilters
  const { activeFacilityId } = appliedFilters
  const activeFacility = facilities?.find(
    (facility: Facility) => facility.facility_id === activeFacilityId
  )
  const [data, setData] = useState<any>([true, {}])
  const fileName = `${moment().unix()}_orangebank_${cadence}_certificate_collateral.xlsx`

  const params = {
    slug_name: company?.slug_name ?? '',
    facility_id: activeFacility.facility_id,
    type: 'portfolio' as CovenantType,
    id: 4,
  }

  const { data: covenantData, isFetching } = useQuery(
    ['covenantData', params],
    () => CovenantService.getRaw(params),
    {
      ...getStaleMins(),
      enabled: getCovenants,
    }
  )

  useEffect(() => {
    setData([
      isFetching ?? false,
      {
        fileUrl: formatDownloadURL(
          `${window.location.origin}/manage/monitor/actions?pid=${company?.slug_name}&facility=${activeFacilityId}&tab=document-centre&fileName=${fileName}`,
          78
        ),
        effectiveDateOptions: getDates('2023-11-29')?.[cadence],
        covenantData,
      },
    ])
  }, [isFetching])

  return data
}

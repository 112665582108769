import React, { useContext, useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import FormInput from '@components/form/form-input'
import { SIGNER_EMAIL } from '@constants/config'
import AuthContext from '@contexts/auth'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Facility } from '@interfaces/facility'
import { FacilityDetailResponse } from '@interfaces/manage-monitor-action'
import {
  WaterfallDetailResponse,
  WaterfallListResponse,
} from '@interfaces/manage-monitor-waterfall'
import { CategoriesResult } from '@interfaces/manage-reporting'
import { SignatureRequest } from '@interfaces/manage-signature'
import { Dialog, DialogBody, DialogHeader } from '@material-tailwind/react'
import { pdf, PDFViewer } from '@react-pdf/renderer'
import { FacilityDetailsService } from '@services/api-manage/facility-details'
import { WaterfallService } from '@services/api-manage/monitor-waterfall'
import SignatureService from '@services/api-manage/signature'

import { useAfterEffects } from '../document-centre/templates/after-effects'
import { SignerInput } from '../document-centre/templates/components'
import { getCustomData } from '../document-centre/templates/custom-data'

import { renderTemplate } from './templates'

interface ReportDialogProps {
  open?: boolean
  handler: (needupdate?: boolean) => void
  fxItems: WaterfallDetailResponse[]
  data: WaterfallDetailResponse[]
  remainder: number
  footerData?: WaterfallDetailResponse[]
  date: Moment
  waterfall: WaterfallListResponse
  currency: string
  category: CategoriesResult
}

const ReportDialog = ({
  open = false,
  handler,
  fxItems,
  data,
  remainder,
  footerData,
  date,
  waterfall,
  currency,
  category,
}: ReportDialogProps) => {
  const [options, setOptions] = useState<Record<string, any>>({})
  const { user } = useAuth0()
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)
  const form = useForm()

  const {
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    getValues,
    register,
    setValue,
  } = form

  const { activeFacilityId } = appliedFilters
  const { facilities = [] } = optionFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id === activeFacilityId
  )

  const facilityFilter = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility_name ?? '',
    facility_id: activeFacility?.facility_id,
  }

  const { afterEffect } = useAfterEffects(category as CategoriesResult)

  const { data: facilityList, isFetching: isFetchingFacilityDetails } =
    useQuery(
      ['debt-facility-list', facilityFilter],
      () => FacilityDetailsService.getDealPartyDetailsRequest(facilityFilter),
      {
        enabled: !!activeFacility?.facility_id && !!company?.slug_name,
      }
    )

  useEffect(() => {
    if (!isFetchingFacilityDetails && !!facilityList) {
      const agent = facilityList?.find(x => x.type.toLowerCase() === 'agent')
      setValue('agent', agent, { shouldValidate: true })

      const agreementDate = moment(activeFacility?.agreement_date).format(
        'Do MMMM YYYY'
      )
      setValue('agreementDate', agreementDate, { shouldValidate: true })

      const borrower = facilityList?.find(
        (x: FacilityDetailResponse) => x.type.toLowerCase() === 'borrower'
      )
      setValue('borrower', borrower, { shouldValidate: true })

      const guarantors = facilityList?.filter(
        (x: FacilityDetailResponse) => x.type.toLowerCase() === 'guarantor'
      )
      setValue('guarantors', guarantors, { shouldValidate: true })
    }
  }, [isFetchingFacilityDetails])

  // Set up signers
  useEffect(() => {
    if (!!activeFacility) {
      const borrower = getValues('borrower')
      const borrowerDistributionList = borrower?.distribution_list
        ? JSON.parse(borrower?.distribution_list)
        : []

      const signer = !!getValues('signers')?.[0]?.selfSigned
        ? { name: user?.name, email: user?.email, title: user?.title }
        : borrowerDistributionList?.[0] ?? {}
      const agent = getValues('agent')
      const agentDistributionList = agent?.distribution_list
        ? JSON.parse(agent?.distribution_list)
        : []
      const agentSigner = {
        ...agentDistributionList?.[0],
        hideSelfSigned: true,
        email: SIGNER_EMAIL ? SIGNER_EMAIL : agentDistributionList?.[0]?.email,
      }

      const roughOptions = category?.signers ? JSON.parse(category.signers) : {}
      roughOptions.signers = [
        signer,
        ...(activeFacility?.waterfall_includes_agent ? [agentSigner] : []),
        ...(roughOptions?.signers ?? []),
      ]
      setOptions(roughOptions)
    }
  }, [category, activeFacility, isFetchingFacilityDetails])

  // Get custom data
  const [isLoadingCustomData, customData] = getCustomData(
    category as CategoriesResult,
    date
  )

  const handleError = () => {
    toast.dismiss()
    toast.error('An Error occurred during signature request creation', {
      autoClose: 5000,
    })
  }
  const handleSuccess = (data: any) => {
    toast.dismiss()
    if (data.data.signature_request_id) {
      _submitWaterfall(data.data)
    } else {
      handleError()
    }
  }

  const { mutate: requestSignature, isLoading: isLoadingSignature } =
    useMutation(
      (params: SignatureRequest) => {
        return SignatureService.createEmbeddedSignature(params)
      },
      {
        onSuccess: handleSuccess,
        onError: handleError,
      }
    )

  const _onSubmitSignature = async (cd: Record<string, any>) => {
    const formData = new FormData()
    formData.append('slug_name', company?.slug_name ?? '')
    user?.email && formData.append('requester_email', `${user.email}`)
    user?.name && formData.append('requester_name', `${user.name}`)
    formData.append('category', 'Waterfall Report')
    formData.append('facility', activeFacility?.facility_name)
    formData.append('effective_date', moment(date).format('DD-MM-YYYY'))
    formData.append(
      'title',
      `${waterfall?.waterfall} - ${waterfall?.period} - ${moment(date).format(
        'DD MMMM yyyy'
      )}`
    )
    formData.append(
      'files',
      await pdf(
        renderTemplate(category, {
          fxItems,
          data,
          footerData,
          date: moment(date).format('Do MMMM YYYY'),
          waterfall,
          facility: activeFacility,
          currency,
          formData: cd,
          customData,
        })
      ).toBlob()
    )
    formData.append(
      'subject',
      `Form of Compliance Certificate ${waterfall?.waterfall} - ${
        waterfall?.period
      } - ${moment(date).format('Do MMMM YYYY')}`
    )
    formData.append(
      'message',
      `${user?.name} has requested your signature via Cascade Debt`
    )
    formData.append(
      'signers',
      JSON.stringify(
        cd.signers?.map((signer: Record<string, any>, idx: number) => ({
          name: signer.name,
          emailAddress: signer.email,
          order: options?.enforceSignOrder ? idx : undefined,
        }))
      )
    )
    formData.append('tags', 'true')
    requestSignature(formData as SignatureRequest)
  }

  const { mutate: submitWaterfall, isLoading: isLoadingSubmit } = useMutation(
    (formData: FormData) => {
      return WaterfallService.submit(formData)
    },
    {
      onSuccess: () => {
        afterEffect(date, customData)
        handler(true)
      },
      onError: handleError,
    }
  )

  const _submitWaterfall = (signatureRequestResponse: any) => {
    const formData = new FormData()
    formData.append(
      'slug',
      activeFacility?.slug_name ?? company?.slug_name ?? ''
    )
    formData.append('facilityId', activeFacility?.facility_id ?? '')
    formData.append('facilityName', activeFacility?.facility_name ?? '')
    formData.append('waterfall', waterfall?.waterfall)
    formData.append('calculationDate', moment(date).format('YYYY-MM-DD'))
    formData.append('requesterName', user?.name ?? '')
    formData.append('status', 'submitted')
    formData.append(
      'signature',
      JSON.stringify({
        signature_request_id: signatureRequestResponse.signature_request_id,
        signatures: signatureRequestResponse.signatures.map((s: any) => ({
          signer_email_address: s.signer_email_address,
          signature_id: s.signature_id,
          status_code: s.status_code,
        })),
        signing_url: signatureRequestResponse.signing_url,
        details_url: signatureRequestResponse.details_url,
        final_copy_uri: signatureRequestResponse.final_copy_uri,
      })
    )
    const finalValueDetail = (footerData ?? []).find(
      detail => detail.operation === 'final_amount'
    )
    formData.append('finalValue', finalValueDetail?.value ?? remainder ?? 0)
    ;[...fxItems, ...data, ...(footerData ?? [])]?.forEach(
      (d: any, i: number) => {
        formData.append(`detail[${i}][defined_term]`, d.defined_term)
        formData.append(
          `detail[${i}][input_value]`,
          d.input_value ?? d.value ?? d.cascade_calculation ?? 0
        )
        d.component?.forEach((c: any, j: number) => {
          formData.append(`detail[${i}][component][${j}][item]`, c.item ?? '')
          formData.append(`detail[${i}][component][${j}][currency]`, c.currency)
          formData.append(
            `detail[${i}][component][${j}][amount]`,
            c.amount ?? 0
          )
          c.transactionProof &&
            formData.append(
              `detail[${i}][component][${j}][transactionProof]`,
              c.transactionProof ?? ''
            )
        })
      }
    )

    submitWaterfall(formData)
  }

  return (
    <Dialog open={open} handler={() => undefined} size="xl">
      <DialogHeader className="text-xl flex justify-between">
        Waterfall
        <XMarkIcon
          onClick={() => handler()}
          className="w-6 h-6 cursor-pointer"
        />
      </DialogHeader>
      <form onSubmit={handleSubmit(_onSubmitSignature)}>
        <DialogBody
          divider
          className="flex flex-row overflow-y-auto h-[80vh] gap-4"
        >
          <PDFViewer showToolbar={false} className="h-full w-full">
            {renderTemplate(category, {
              fxItems,
              data,
              footerData,
              date: moment(date).format('Do MMMM YYYY'),
              waterfall,
              facility: activeFacility,
              currency,
              formData: getValues(),
              customData,
            })}
          </PDFViewer>
          <div className="flex flex-col items-center m-5 w-1/3 overflow-auto">
            <div className="flex flex-col content-between">
              <div className="font-bold text-lg pb-5">
                Fill in below fields to generate document
              </div>
              <SignerInput form={form} user={user} signers={options} />
            </div>
            {!options?.hideDisclosure && (
              <FormInput
                type="textarea"
                label={{ start: 'Disclosure Notice' }}
                value={getValues('disclosure')}
                {...register('disclosure', {
                  disabled:
                    isSubmitting || isLoadingSubmit || isLoadingSignature,
                  onChange: (e: any) => {
                    setValue('disclosure', e.target.value, {
                      shouldValidate: true,
                    })
                  },
                })}
                error={errors?.disclosure?.message as string}
              />
            )}
            <Button
              type="submit"
              color="primary"
              disabled={
                isSubmitting ||
                isLoadingSubmit ||
                isLoadingSignature ||
                isLoadingCustomData ||
                !isValid
              }
            >
              {(isSubmitting || isLoadingSubmit || isLoadingSignature) && (
                <ArrowPathIcon className="animate-spin text-primary-main w-4 mr-2" />
              )}
              Request
            </Button>
          </div>
        </DialogBody>
      </form>
    </Dialog>
  )
}

export default ReportDialog

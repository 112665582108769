import React from 'react'
import moment from 'moment'

import { formatCurrency } from '@helpers/number-formatter'
import { WaterfallTemplateProps } from '@interfaces/manage-reporting'
import { Document, Page, Text, View } from '@react-pdf/renderer'

import { Link } from '../../../document-centre/templates/components'
import { styles } from '../report-template-style'

const table1Rows = [
  {
    number: 'FIRST',
    description:
      ', on a pari passu basis, (1) to the Servicer (so long as neither Parent nor any of its Affiliates is the Servicer), any Servicing Fees and any expenses reimbursable under the Servicing Agreement (including, any Servicing Expenses) not previously paid or reimbursed for the subject Remittance Period, (2) to the Custodian (so long as neither Parent nor any of its Affiliates is the Custodian), any Custodial Fees and any expenses reimbursable under the Servicing Agreement or the Backup Servicing Agreement, as applicable, not previously paid or reimbursed, (3) to any Backup Servicer, if any, any Backup Servicer Fees and any expenses reimbursable under the Backup Servicing Agreement not previously paid or reimbursed, each for the subject Remittance Period;',
  },
  {
    number: 'SECOND',
    description:
      ', after the occurrence and continuance of an Event of Default, the amount necessary to repay the aggregate outstanding Advances until the aggregate outstanding Advances have been reduced to zero;',
  },
  {
    number: 'THIRD',
    description:
      ', to Agent, for the benefit of Lenders, an amount equal to any Borrowing Base Deficiency;',
  },
  {
    number: 'FOURTH',
    description:
      ', to the Servicer (to the extent Parent or any of its Affiliates is the Servicer), any Servicing Fees not previously paid or reimbursed for the subject Remittance Period;',
  },
  {
    number: 'FIFTH',
    description:
      ', to Agent, for the benefit of Lenders, any accrued and unpaid interest on the aggregate outstanding Advances);',
  },
  {
    number: 'SIXTH',
    description:
      ', to Agent, for the benefit of Lenders, reimbursement of any and all reasonable fees, out of pocket costs and expenses associated with its administration and enforcement of, and any amendments made or waivers granted with respect to, this Agreement or the Loan Documents;',
  },
  {
    number: 'SEVENTH',
    description:
      ', during the Revolving Period to Borrower, any Collections remaining after application of such amounts pursuant to clauses first through sixth above;',
  },
  {
    number: 'EIGHTH',
    description:
      ', during the period beginning on the termination of the Revolving Period, to Agent, for the benefit of Lenders until the Aggregate Outstanding Advances have been reduced to zero, any Collections remaining after application of such amounts pursuant to clauses first through seventh above; and',
  },
  {
    number: 'NINTH',
    description:
      ', to Borrower, any Collections remaining after application of such amounts pursuant to clauses first through eighth above.',
  },
]

const FlexpathWaterfallTemplate = ({
  data,
  date,
  customData,
  facility,
  formData,
}: WaterfallTemplateProps) => {
  // table1Values directly relate to the index of table1Rows above
  const table1Values = [
    Number(data[2]?.value ?? '0'),
    Number(data[3]?.value ?? '0'),
    Number(data[4]?.value ?? '0'),
    Number(data[5]?.value ?? '0'),
    Number(data[6]?.value ?? '0'),
    Number(data[7]?.value ?? '0'),
  ]
  table1Values.push(
    Number(data[0]?.value ?? '0') -
      table1Values.reduce((acc, val) => acc + val, 0) -
      (data[1]?.value ?? 0),
    0
  )
  table1Values.push(table1Values[6] + table1Values[7])

  const toServicer = table1Values[0] + table1Values[3]
  const toCustodian = 0
  const toBackupServicer = 0
  const toAgent = table1Values[1] + table1Values[4] + table1Values[5]
  const toBorrower = table1Values[6] + table1Values[7]

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.textSpaced}>
          <Text
            style={{
              ...styles.textSpaced,
              fontFamily: 'Times-Bold',
              textDecoration: 'underline',
            }}
          >
            Company Name:
          </Text>{' '}
          Flexpath Capital
        </Text>
        <Text
          style={{
            ...styles.textSpaced,
            fontFamily: 'Times-Bold',
            textDecoration: 'underline',
          }}
        >
          Remittance Report
        </Text>
        <Text style={{ ...styles.textSpaced, marginBottom: 2 }}>
          Supported by:
        </Text>
        <Link href={customData?.fileUrl} width="100%" />
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'flex-end',
            marginVertical: 10,
          }}
        >
          <Text style={{ ...styles.textSpaced, marginVertical: 0 }}>
            Start of Period:{' '}
            {moment
              .utc(date, 'Do MMMM YYYY')
              .startOf('month')
              .format('YYYY-MM-DD')}
          </Text>
          <Text style={{ ...styles.textSpaced, marginVertical: 0 }}>
            End of Period:{' '}
            {moment.utc(date, 'Do MMMM YYYY').format('YYYY-MM-DD')}
          </Text>
          <Text style={{ ...styles.textSpaced, marginVertical: 0 }}>
            Waterfall Date:{' '}
            {moment.utc(date, 'Do MMMM YYYY').format('YYYY-MM-DD')}
          </Text>
        </View>
        <Text style={{ ...styles.text, marginBottom: 14 }}>
          According to Section 4.3 of the Loan and Security Agreement between{' '}
          {formData?.agent?.legal_name?.toUpperCase()} and{' '}
          {formData?.borrower?.legal_name?.toUpperCase()}. On each Report Date
          until such time as all Obligations have been satisfied, or until the
          Servicer and Agent shall have determined (which determination shall be
          made in a commercially reasonable manner) that the Receivables have
          been exhausted, Borrower shall, with respect to the Receivables,
          deliver a Remittance Report, the Computer Tape and a Borrowing Base
          Certificate to Agent. On each Remittance Date, Agent shall instruct
          the Account Bank to remit from the Collection Account all Collections
          in respect of the Receivables for the related Remittance Period and
          all related net investment earnings on amounts on deposit in the
          Collection Account in the following order, priority and amounts:
        </Text>

        {/*
         * =================================================================
         * Table 1
         * =================================================================
         */}
        <View style={styles.tableContainer}>
          <View wrap={false} style={styles.tableRow}>
            <Text
              style={{
                ...styles.text,
                margin: 0,
                borderRight: '1px solid black',
                width: '80%',
              }}
            >
              AMOUNT IN COLLECTIONS ACCOUNT
            </Text>
            <Text
              style={{
                ...styles.text,
                margin: 0,
                width: '20%',
                textAlign: 'right',
              }}
            >
              {formatCurrency(Number(data[0]?.value ?? '0'))}
            </Text>
          </View>
          <View wrap={false} style={styles.tableRow}>
            <Text
              style={{
                ...styles.text,
                margin: 0,
                borderRight: '1px solid black',
                width: '80%',
              }}
            >
              MINIMUM CASH IN COLLECTIONS ACCOUNT
            </Text>
            <Text
              style={{
                ...styles.text,
                margin: 0,
                width: '20%',
                textAlign: 'right',
              }}
            >
              {formatCurrency(Number(data[1]?.value ?? '0'))}
            </Text>
          </View>
          <View wrap={false} style={styles.tableRow}>
            <Text
              style={{
                ...styles.text,
                margin: 0,
                borderRight: '1px solid black',
                width: '80%',
              }}
            >
              {' '}
            </Text>
            <Text
              style={{
                ...styles.text,
                margin: 0,
                width: '20%',
                textAlign: 'right',
              }}
            >
              {' '}
            </Text>
          </View>
          {table1Rows.map((row, idx) => (
            <View wrap={false} key={`table1-row${idx}`} style={styles.tableRow}>
              <Text
                style={{
                  ...styles.text,
                  margin: 0,
                  borderRight: '1px solid black',
                  width: '80%',
                }}
              >
                <Text
                  style={{
                    ...styles.text,
                    margin: 0,
                    fontFamily: 'Times-Bold',
                  }}
                >
                  {row.number}
                </Text>
                {row.description}
              </Text>
              <Text
                style={{
                  ...styles.text,
                  margin: 0,
                  width: '20%',
                  textAlign: 'right',
                }}
              >
                {formatCurrency(table1Values[idx])}
              </Text>
            </View>
          ))}
        </View>
      </Page>
      <Page style={styles.body}>
        {/*
         * =================================================================
         * Table 2
         * =================================================================
         */}
        <View style={{ ...styles.tableContainer, marginBottom: 10 }}>
          <View style={{ ...styles.tableRow, backgroundColor: 'lightgray' }}>
            <Text
              style={{
                ...styles.text,
                margin: 0,
                fontFamily: 'Times-Bold',
              }}
            >
              Remittance Summary
            </Text>
            <Text style={{ ...styles.text, margin: 0, textAlign: 'right' }}>
              {' '}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.text, margin: 0 }}>To Servicer</Text>
            <Text style={{ ...styles.text, margin: 0, textAlign: 'right' }}>
              {formatCurrency(toServicer)}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.text, margin: 0 }}>To Custodian</Text>
            <Text style={{ ...styles.text, margin: 0, textAlign: 'right' }}>
              {formatCurrency(toCustodian)}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.text, margin: 0 }}>
              To Backup Servicer
            </Text>
            <Text style={{ ...styles.text, margin: 0, textAlign: 'right' }}>
              {formatCurrency(toBackupServicer)}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.text, margin: 0 }}>To Agent</Text>
            <Text style={{ ...styles.text, margin: 0, textAlign: 'right' }}>
              {formatCurrency(toAgent)}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.text, margin: 0 }}>
              To Borrower (including Servicer Fee if applicable)
            </Text>
            <Text style={{ ...styles.text, margin: 0, textAlign: 'right' }}>
              {formatCurrency(toBorrower)}
            </Text>
          </View>
          <View style={{ ...styles.tableRow, backgroundColor: 'darkgray' }}>
            <Text
              style={{
                ...styles.text,
                margin: 0,
                fontFamily: 'Times-Bold',
              }}
            >
              Totals
            </Text>
            <Text style={{ ...styles.text, margin: 0, textAlign: 'right' }}>
              {formatCurrency(
                toServicer +
                  toCustodian +
                  toBackupServicer +
                  toAgent +
                  toBorrower
              )}
            </Text>
          </View>
        </View>
        <Text style={styles.text}>
          The Company named in the box above labeled &quot;Company Name&quot;
          (the &quot;Company&quot;), by its duly authorized officer signing
          below, hereby certifies that (a) the information set forth in this
          certificate is true and correct as of the date(s) indicated herein and
          (b) the Company is in compliance with all terms and pursuant to which
          this certificate is delivered (the &quot;Agreement&quot;) and (ii) any
          and all documents, instruments and agreements provisions in (i) the
          loan or other agreement between the Company and Variant evidencing,
          governing or securing the Agreement or otherwise executed in
          connection therewith.
        </Text>

        {/*
         * =================================================================
         * signature
         * =================================================================
         */}
        <View
          wrap={false}
          style={{
            ...styles.tableContainer,
            position: 'absolute',
            bottom: 65,
            right: 35,
            left: 35,
            border: '1px solid lightgray',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              borderBottom: '1px solid lightgray',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
                paddingLeft: 4,
                paddingBottom: 10,
              }}
            >
              <Text style={{ ...styles.text, margin: 0, fontWeight: 'bold' }}>
                Prepared by:
              </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 50,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text style={{ ...styles.text, color: 'white' }}>
                  [sig|req|signer1|signature]
                </Text>
              </View>
              <Text
                style={{
                  ...styles.text,
                  margin: 0,
                  marginTop: 6,
                  fontFamily: 'Times-Bold',
                }}
              >
                Name:{' '}
                <Text style={{ ...styles.text, margin: 0 }}>
                  {formData?.signers?.[0]?.name}
                </Text>
              </Text>
              <Text
                style={{ ...styles.text, margin: 0, fontFamily: 'Times-Bold' }}
              >
                Title:{' '}
                <Text style={{ ...styles.text, margin: 0 }}>
                  {formData?.signers?.[0]?.title}
                </Text>
              </Text>
            </View>
            {facility?.waterfall_includes_agent && (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%',
                  borderLeft: '1px solid lightgray',
                  paddingLeft: 4,
                  paddingBottom: 10,
                }}
              >
                <Text
                  style={{
                    ...styles.text,
                    margin: 0,
                    fontFamily: 'Times-Bold',
                  }}
                >
                  Authorized Signature
                  <Text
                    style={{
                      ...styles.text,
                      margin: 0,
                      verticalAlign: 'super',
                      fontSize: 7,
                    }}
                  >
                    {' '}
                    (1)
                  </Text>
                  :
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 50,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text style={{ ...styles.text, color: 'white' }}>
                    [sig|req|signer2|signature]
                  </Text>
                </View>
                <Text
                  style={{
                    ...styles.text,
                    margin: 0,
                    marginTop: 6,
                    fontFamily: 'Times-Bold',
                  }}
                >
                  Name:{' '}
                  <Text style={{ ...styles.text, margin: 0 }}>
                    {formData?.signers?.[1]?.name}
                  </Text>
                </Text>
                <Text
                  style={{
                    ...styles.text,
                    margin: 0,
                    fontFamily: 'Times-Bold',
                  }}
                >
                  Title:{' '}
                  <Text style={{ ...styles.text, margin: 0 }}>
                    {formData?.signers?.[1]?.title}
                  </Text>
                </Text>
              </View>
            )}
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              paddingBottom: 10,
              justifyContent: 'flex-end',
            }}
          >
            <Text style={styles.text}>
              <Text
                style={{
                  ...styles.text,
                  margin: 0,
                  verticalAlign: 'super',
                  fontSize: 7,
                }}
              >
                (1){' '}
              </Text>
              If this document is being transmitted electronically, the Borrower
              acknowledges that by entering the name of its duly authorized
              officer, that officer has reviewed the Certificate and affirmed
              the representations and warranties referenced above.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default FlexpathWaterfallTemplate
